<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <OrderSearch :showOrderStatus="'OrderVoid'"></OrderSearch>
      <div>
        <a-button
          v-if="isAudit && selectedRowKeys.length > 0 && selectedRows[0].orderStatus != 3"
          type="primary"
          @click="toHandler('audit')"
          >审核
        </a-button>
        <a-button v-if="isVoid && selectedRowKeys.length > 0" type="primary" @click="toHandler('void')">作废</a-button>
        <a-button v-if="isCancel && selectedRowKeys.length > 0" type="primary" @click="toHandler('cancel')"
          >取消订单
        </a-button>
        <a-button v-if="isCancel" type="primary" @click="toHandler('check')">查看</a-button>
      </div>
      <!-- 数据表格 -->
      <OrderList
        :url="'/api/order/dealer/dealerOrderInfo/list'"
        :status="[1]"
        :columnsData="columns"
        @orderData="getSelectedRows($event)"
      ></OrderList>
      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal"></OrderInfo-edit-modal>
      <EditAddressModal ref="EditAddressModal"></EditAddressModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <a-modal
        title="审核"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <div v-if="handle == 'audit'">
            <a-form-model-item label="是否通过">
              <a-radio-group v-model="status" @change="onStatusChange">
                <a-radio :value="4"> 通过</a-radio>
                <a-radio :value="3"> 拒绝</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="原因" v-if="statusRadio == 3">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
          <div v-if="handle == 'cancel' || handle == 'void'">
            <a-form-model-item label="理由">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">作废</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit' && statusRadio == 3" type="danger" @click="onModifyOrderStatus('refuse')">
            确定
          </a-button>
          <a-button v-if="handle == 'audit' && statusRadio == 4" type="primary" @click="onModifyOrderStatus('audit')">
            确定
          </a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModal.vue'
import { delOrderInfo, listOrderInfo, selectByIdOrderInfo } from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { checkPermission } from '@/utils/permissions'

export default {
  name: 'OrderVoid',
  components: {
    OrderInfoEditModal,
    EditAddressModal,
    InvoiceEditModal,
    OrderInfoCheckModal,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'bigAreaName',
        'dealerName',
        'orderBelongsName',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'createTime',
        'address',
        'consigneeType',
        'supplierName',
        'deliveryProperties',
        'expectTime',
        'purchaseAndSalesContractType',
        'flagFreeShipping',
      ],
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isAdd: checkPermission('order:info:add'),
      isEdit: checkPermission('order:info:edit'),
      isDelete: checkPermission('order:info:delete'),
      isEditAddress: checkPermission('order:info:edit_address'),
      isEditInvoice: checkPermission('order:info:edit_invoice'),
      isCheck: checkPermission('order:info:check'),
      isAudit: checkPermission('order:info:audit'),
      isCancel: checkPermission('order:info:cancel'),
      isVoid: checkPermission('order:info:void'),
      visible: false,
      confirmLoading: false,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
      status: 4,
      statusRadio: 4,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.searchData.statusList = [1]
      listOrderInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '合同类型').then((res) => {
        // 获取字典合同类型
        this.contractTypeList = res.body
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '订单类型').then((res) => {
        // 获取字典订单类型
        this.orderTypeList = res.body
      })
    },
    getSelectedRows(rows) {
      this.selectedRows = rows
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0])
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      if (name == 'audit') {
        this.onModifyStatus(4)
      } else if (name == 'refuse') {
        this.onModifyStatus(3)
      } else if (name == 'void') {
        this.onModifyStatus(1)
      } else if (name == 'cancel') {
        this.onModifyStatus(2)
      }
    },

    onModifyStatus(status) {
      let obj = {
        orderId: this.selectedRows[0].id,
        orderStatus: status,
        verifyRefuseCause: this.rejectReason,
      }
      this.axios.post(`/api/order/order/orderInfo/verifyOrderInfo`, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.getData()
          this.rejectReason = ''
          this.selectedRowKeys = []
        }
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}

::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}

.footer-bts {
  text-align: right;
}
</style>